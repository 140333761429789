import { useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CountrySelector } from '@snippets';

import { useGeolocation, useLocalStorage } from '@hooks';
import { useGlobalContext } from '@contexts';
import { useRouter } from 'next/router';

export function GeolocationModal() {
  const settings = useSettings();
  const {
    heading = '',
    subheading = '',
    countrySelector = true,
    locations = [],
  } = { ...settings?.header?.userLocationModal };
  const [storedValue] = useLocalStorage('Country|underoutfit');
  const { userHeaders } = useGeolocation();
  const router = useRouter();

  const {
    actions: { openModal, closeModal },
  } = useGlobalContext();

  const [modalClosed, setModalClosed] = useLocalStorage(
    'countryModalClosed',
    false
  );

  const isUSA = userHeaders?.isoCode === 'US';

  const hideInThisCountry =
    locations.length >= 0 && locations.includes(userHeaders?.isoCode);

  const showModal = !isUSA || !hideInThisCountry || !router?.query?.country;

  const handleOnClose = () => {
    setModalClosed(true);
    closeModal();
  };

  const handleCountryChange = () => {
    handleOnClose();
  };
  console.log('GeolocationModal is render', storedValue);
  console.log({ query: router?.query?.country, stored: storedValue?.isoCode });
  console.log(
    '(showModal && !modalClosed) || !router?.query?.country',
    showModal
  );
  console.log({
    isUSA,
    hideInThisCountry,
    store: storedValue?.isoCode,
    router: !router?.query?.country,
    showModal,
    closed: modalClosed,
  });
  // useEffect(() => {
  //   console.log('showModal && !modalClosed', showModal && !modalClosed);
  //   if (showModal && !modalClosed) {
  //     openModal(
  //       <div
  //         data-comp={GeolocationModal.displayName}
  //         className="bg-background_medium mx-auto min-h-[500px] w-full min-w-[300px] max-w-[400px] overflow-auto p-10 text-center"
  //       >
  //         <h2 className="mb-6 text-center">{heading}</h2>
  //         {subheading && <p className="my-8">{subheading}</p>}
  //         <div className="mt-12 flex justify-center">
  //           {countrySelector ? (
  //             <CountrySelector
  //               handleOnChange={handleCountryChange}
  //               showFlags
  //               showCountryName
  //               color="text-black"
  //             />
  //           ) : null}
  //         </div>
  //       </div>
  //     );
  //   }
  // }, [hideInThisCountry, userHeaders?.isoCode, locations]);

  if ((!heading && !subheading) || !showModal || modalClosed) return null;

  return null;
}

GeolocationModal.displayName = 'GeolocationModal';
